import React, {useEffect, useMemo, useState, useCallback} from 'react';
import {BrowserRouter as Router, Switch, Route, Redirect} from "react-router-dom";
import Api from 'tide-api';
import apiConfig from './services/api/api-config';
import {ApiContext} from "./services/api/api-config";
import 'bootstrap/dist/css/bootstrap-grid.min.css'
import './assets/fonts/fonts.scss';
import './assets/styles/App.scss';
import './assets/styles/notifications.scss';
import {LOGIN_STATE} from "tide-api";
import store from "./services/redux/store";
import {useSelector} from "react-redux";
import Splash from "./components/Splash";
import LoadingBar from "./components/LoadingBar/LoadingBar";
import moment from "moment";
import 'moment/locale/es';
import classNames from 'classnames';
import './services/numberUtils';
import getAppRoutes from "./services/routes/appRoutes";
import notLoggedRoutes from "./services/routes/notLoggedRoutes";
import { ToastContainer } from "react-toastr";
import {getNotifier, setNotifier} from "./services/notifierUtils";
import 'animate.css';
import {LoungeContext} from "./services/modelUtils/loungeUtils";
import useBoolean from "./hooks/useBoolean";
import MessageSplash from "./components/Splash/MessageSplash";
import {version} from './pckg';
import SecurityManager, {SecurityContext} from "./services/SecurityManager";
import InitialWelcomeScreen from "./components/InitialWelcomeScreen/InitialWelcomeScreen";
import * as serviceWorker from './serviceWorker';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowAltCircleUp} from "@fortawesome/free-solid-svg-icons";
import compareVersions from "compare-versions";
import ErrorBoundary from "./components/ErrorBoundary/ErrorBoundary";
import {isRemoteDavinci} from "./services/remoteDavinciUtils";

moment.locale("es");

const api = new Api({...apiConfig, reduxStore: store});
let savedLounge;
try {
    savedLounge = JSON.parse(window.localStorage.lounge);
}
catch (e) {}

// Reload on every logout.
// Intended to prevent any memory leak in the browser js context
const logout = api.logout;
api.logout = function(){
    logout.call(this).then(()=>window.location.reload())
}

function App() {

    const loggedIn=useSelector(({api})=>api.loggedIn===LOGIN_STATE.LOGGED_IN);

    const actualLounge=useSelector(({api})=>api.discoverLounge||savedLounge);
    const [loungeNotFound,setLoungeNotFound]=useBoolean();

    useEffect(()=>{
        api.me.get({loadingId:'Initializing.me'});
        api.discoverLounge.get({preventNotifier: true})
            .then((lounge)=> window.localStorage.lounge=JSON.stringify(lounge))

    },[setLoungeNotFound]);

    const loading=useSelector(({loadingIds})=>!!loadingIds['Initializing.me']);
    const me=useSelector(({api})=>api.me);

    const securityManager=useMemo(()=>{
        if(me)
            return new SecurityManager(me);
        else
            return null;
    },[me]);

    const blurred=useSelector(({appBlurred})=>appBlurred);

    const routes= loggedIn && me?
        getAppRoutes(securityManager)
        :notLoggedRoutes;

    const splash=!!(loading || (loggedIn && !me));

    // --  Show welcome screen on login
    const [showWelcome, setShowWelcome]=useState(loggedIn?'showed':'no');
    useEffect(()=>{
        if(showWelcome==='no' && loggedIn){
            setShowWelcome('show');
            setTimeout(()=>setShowWelcome('showed'), 3000);
        }
        if(loggedIn && isRemoteDavinci() && securityManager && !securityManager.canUseRemoteDavinci() ){
            getNotifier().error("No tienes permiso de utilizar esta versión de Davinci.");
            api.logout();
        }

    },[showWelcome, loggedIn, securityManager]);

    const updateVersion=useCallback(()=>{
        serviceWorker.unregister();
        window.location.reload();
    },[]);

    const latestVersion=useSelector(({latestAppVersion})=>latestAppVersion);
    const shouldUpdate=compareVersions(latestVersion, version)===1;

    if(loungeNotFound)
        return <MessageSplash message={'Servidor desconectado'} />;

    return (
        <LoungeContext.Provider value={actualLounge}>
            <ApiContext.Provider value={api} >
                <ErrorBoundary>
                    {showWelcome==='show'&&
                    <InitialWelcomeScreen/>}

                    <ToastContainer
                        ref={setNotifier}
                        preventDuplicates
                    />
                    <div className={classNames("App", blurred?'blurred':'')}>
                        <LoadingBar/>

                        <SecurityContext.Provider value={securityManager}>
                            <Router>
                                {splash ?
                                    <Splash/> :
                                    <Switch>
                                        {routes.map(route =>
                                            <Route key={route.path} path={route.path} component={route.component}
                                                   exact={route.exact !== false}/>
                                        )}
                                        <Redirect from='/' to={routes[0].path}/>
                                    </Switch>
                                }
                            </Router>
                        </SecurityContext.Provider>
                        <p className={"version " + (shouldUpdate?"update":'')} onClick={updateVersion}>{version} {shouldUpdate&&<FontAwesomeIcon icon={faArrowAltCircleUp}/>}</p>
                    </div>
                </ErrorBoundary>
            </ApiContext.Provider>
        </LoungeContext.Provider>
    );
}

export default App;
