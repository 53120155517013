import React from 'react';
import './InitialWelcomeScreen.scss';
import {ReactComponent as WaveTop} from "../../assets/images/icons/waveTop.svg";
import {ReactComponent as WaveBottom} from "../../assets/images/icons/waveBottom.svg";



const InitialWelcomeScreen = () => {

    return (
        <div className="InitialWelcomeScreen">
            <div className="waveTop"><WaveTop/></div>
            <div className="waveTop-2"><WaveTop/></div>
            <div className="waveTop-3"><WaveTop/></div>
            <div className="waveBottom"><WaveBottom/></div>
            <div className="waveBottom-2"><WaveBottom/></div>
            <div className="waveBottom-3"><WaveBottom/></div>
            <div className="InitialWelcomeScreen-text">
                <span>¡Sonríe!</span>
                <div className="faceContainer">
                    <div className="faceEyes-row">
                        <div className="faceEye right"/>
                        <div className="faceEye left"/>
                    </div>
                    <div className="mouthRow">
                        <div className="mouth"/>
                    </div>
                </div>
                <p>Hoy es un gran día para hacer felices a nuestros huéspedes</p>
            </div>
        </div>
    )
};
export default InitialWelcomeScreen